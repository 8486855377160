import { JSX, onMount, Show } from "solid-js";
import c from "class-c";
import { Motion, Presence } from "solid-motionone";

import USolid from "@repo/utils-solid/USolid";

import clickOutside from "../directives/clickOutside";
import createDomRect from "../signals/createDomRect";

import styles from "./FloatingWithAnchor.module.scss";

declare namespace FloatingWithAnchor {
  type Props = {
    class?: string;
    anchor: JSX.Element;
    show: boolean | "auto";
    onClickOutside?(): void;
    children?: JSX.Element;
  };
}

function FloatingWithAnchor({
  class: className,
  anchor,
  show,
  onClickOutside,
  children,
}: D<FloatingWithAnchor.Props>) {
  const resolvedAnchor = USolid.children(() => anchor);

  onMount(() => {
    if (resolvedAnchor() instanceof Array)
      throw new Error("`anchor` must only have one element.");

    if (!(resolvedAnchor() instanceof HTMLElement))
      // In order to use createDomRect to track positioning
      throw new Error("`anchor` must be instance of HTMLElement.");
  });

  const rect = createDomRect();

  rect.ref(resolvedAnchor() as any);

  return (
    <>
      {resolvedAnchor()}
      <Presence>
        <Show when={show && rect()}>
          <Motion.div
            ref={onClickOutside && clickOutside.asRef(onClickOutside)}
            class={c`${styles.floating} ${className}`}
            initial={{ opacity: 0, y: -8 }}
            animate={{
              opacity: 1,
              y: 0,
              top: `${rect()!.bottom}px`,
              left: `${rect()!.left}px`,
              width: `${rect()!.width}px`,
            }}
            exit={{ opacity: 0, y: -8 }}
            transition={{
              duration: 0.2,
              top: { duration: 0 },
              left: { duration: 0 },
              width: { duration: 0 },
            }}
          >
            {children}
          </Motion.div>
        </Show>
      </Presence>
    </>
  );
}

export default FloatingWithAnchor;
